import * as React from 'react';
import { useState } from 'react';
import axiosApiInstance from '../../../api/axiosClient';
import { Button, notification, Tooltip } from 'antd';
import { ModalForm } from '@ant-design/pro-components';
import { FileExcelOutlined, FileOutlined } from '@ant-design/icons';
import { useAtom } from 'jotai';
import { releasesViewAtom } from './ddex_atoms';
// import * as XLSX from "xlsx";

type Props = {
    id?: number;
};

const ExportTemplateExcel = (props: Props) => {
    const [exporting, setExporting] = useState(false);
    const [releasesView, setReleasesView] = useAtom(releasesViewAtom);

    const exportExcel = () => {
        setExporting(true);
        notification.info({ message: 'Operation started' });
        return axiosApiInstance
            .post(`/api/products/releases/export-template`, null, {
                responseType: 'blob'
            })
            .then((res) => {
                //download
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'template.xlsx');
                document.body.appendChild(link);
                link.click();

                // sleep for 1 second
                // setTimeout(() => {
                // }, 1000);

                notification.success({ message: 'Exported successfully' });
                setExporting(false);
                return true;
            })
            .catch((reason) => {
                setExporting(false);
                return false;
            });
    };

    return (
        <ModalForm
            width={400}
            title={'Export Template?'}
            trigger={
                <Tooltip title={'Download Excel Template'}>
                    <Button
                        type={'text'}
                        icon={<FileOutlined />}
                        // icon={<DeleteOutlined/>}
                    />
                </Tooltip>
            }
            submitter={{ submitButtonProps: { loading: exporting } }}
            autoFocusFirstInput
            modalProps={{
                destroyOnClose: true,
                okText: 'Yes',
                cancelText: 'No'
            }}
            onFinish={async (values) => {
                return exportExcel();
            }}
        >
            {/*Export Creators to excel?*/}
        </ModalForm>
    );
};

export default ExportTemplateExcel;
