import React, { useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

type Props = {
    onClick: () => Promise<any>;
};

const DownloadBinariesModal = ({ onClick }: Props) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('');

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        await onClick();
        setOpen(false);
        setConfirmLoading(false);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    return (
        <>
            <Tooltip title='Download All'>
                <Button type='link' icon={<DownloadOutlined />} onClick={showModal}></Button>
            </Tooltip>
            <Modal
                title='Are you sure you want to Download all files?'
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText='Download'
                cancelText='Cancel'
            >
                <p>{modalText}</p>
            </Modal>
        </>
    );
};

export default DownloadBinariesModal;
