import ProCard from '@ant-design/pro-card';
import ProTable from '@ant-design/pro-table';
import { Modal, Skeleton, Table } from 'antd';
import React from 'react';

const ModalLoading = () => {
    return (
        <Modal width={1600} open title={<Skeleton active title paragraph={{ rows: 0 }} />}>
            <Skeleton active paragraph={{ rows: 10 }} />
            <ProCard split='vertical'>
                <ProCard>
                    <Table loading />
                </ProCard>
                <ProCard>
                    <Table loading />
                </ProCard>
            </ProCard>
        </Modal>
    );
};

export default ModalLoading;
