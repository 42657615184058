import ProTable, { ProColumns } from '@ant-design/pro-table';
import axiosApiInstance from '../../../api/axiosClient';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Badge,
    Button,
    Checkbox,
    Col,
    Form,
    FormInstance,
    Input,
    message,
    Modal,
    Popover,
    Row,
    Space,
    Spin,
    Table,
    TableColumnsType,
    Tooltip,
    Typography
} from 'antd';
import {
    CheckCircleOutlined,
    CloudDownloadOutlined,
    DownloadOutlined,
    EditOutlined,
    FileImageOutlined,
    PlusOutlined,
    SearchOutlined,
    StopOutlined,
    WarningOutlined
} from '@ant-design/icons';
import { DDEXPageContext, isModalOpenAtom } from './DDEXMainPage';
import { atom, useAtom } from 'jotai';
import { useContext, useEffect, useState, useRef } from 'react';
import { releasesViewAtom } from './ddex_atoms';
import { useLocation, useNavigate } from 'react-router-dom';
import ExportReleasesExcel from './ExportReleasesExcel';
import ImportUpdateReleasesExcel from './ImportUpdateReleasesExcel';
import dayjs from 'dayjs';
import ProCard from '@ant-design/pro-card';
import UploadRelease from './UploadRelease';
import DeleteReleaseUpload from './DeleteReleaseUpload';
import ModifyRelease from './ModifyRelease';
import TakedownRelease from './TakedownRelease';
import { Link } from 'react-router-dom';
import ExportTemplateExcel from './ExportTemplateExcel';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faB } from '@fortawesome/free-solid-svg-icons';
import DownloadBinariesModal from './DownloadAllModal';

type upcToPlatformMapType = { [k: string]: Array<CheckboxValueType> };
type upcToFormRefMapType = { [k: string]: FormInstance<any> };
type selectedTotalsType = {
    youtube: number;
    believe: number;
    spotify: number;
    total: number;
};

const upcToPlatformMapAtom = atom<upcToPlatformMapType>({});
const upcToFormRefMapAtom = atom<upcToFormRefMapType>({});
const selectedTotalsAtom = atom<selectedTotalsType>({
    youtube: 0,
    believe: 0,
    spotify: 0,
    total: 0
});

const getSelectedUPCs = (upcMap: upcToPlatformMapType) =>
    Object.keys(upcMap).filter((k) => upcMap[k].length > 0);

const UploadStatusBadgeMap = {
    uploaded: <Badge text='uploaded' color='green' />,
    modified: <Badge text='modified' color='blue' />,
    takedown: <Badge text='takedown' color='red' />
};

const CHECKBOX_PADDING = '5px';

const CHECKBOX_COL_STYLE = {
    padding: `0px ${CHECKBOX_PADDING}`
};

type UploaderProps = {
    entity: API.MusicDistribution.MusicRelease;
};

type CheckAllProps = {
    selectAllFunc: (platform: string, checked: boolean) => void;
};

// type UploadRowDataType = {
//     key: string;
//     upc: string;
//     title: string;
//     selected: {
//         youtube: boolean;
//         believe: boolean;
//         spotify: boolean;
//     };
// };

const CheckAllCheckbox = ({ selectAllFunc }: CheckAllProps) => {
    const [selectedTotals] = useAtom(selectedTotalsAtom);
    return (
        <Checkbox.Group>
            <Row style={{ width: 60 }}>
                <Col span={8} style={CHECKBOX_COL_STYLE}>
                    <Checkbox
                        value={'youtube'}
                        indeterminate={
                            selectedTotals.total > selectedTotals.youtube && selectedTotals.youtube > 0
                        }
                        onChange={(e) => {
                            const checked = e.target.checked;
                            selectAllFunc('youtube', checked);
                        }}
                    />
                </Col>
                <Col span={8} style={CHECKBOX_COL_STYLE}>
                    <Checkbox
                        value={'believe'}
                        indeterminate={
                            selectedTotals.total > selectedTotals.believe && selectedTotals.believe > 0
                        }
                        onChange={(e) => {
                            const checked = e.target.checked;
                            selectAllFunc('believe', checked);
                        }}
                    />
                </Col>
                <Col span={8} style={CHECKBOX_COL_STYLE}>
                    <Checkbox
                        value={'spotify'}
                        indeterminate={
                            selectedTotals.total > selectedTotals.spotify && selectedTotals.spotify > 0
                        }
                        onChange={(e) => {
                            const checked = e.target.checked;
                            selectAllFunc('spotify', checked);
                        }}
                    />
                </Col>
            </Row>
        </Checkbox.Group>
    );
};

const UploaderCheckbox = ({ entity }: UploaderProps) => {
    const [, setUpcToPlatformMap] = useAtom(upcToPlatformMapAtom);
    const [, setUpcToFormRefMap] = useAtom(upcToFormRefMapAtom);
    const [, setSelectedTotals] = useAtom(selectedTotalsAtom);
    const [form] = Form.useForm();

    useEffect(() => {
        setUpcToFormRefMap((m) => {
            m[entity.upc_ean] = form;
            return m;
        });
    }, [entity.upc_ean, setUpcToFormRefMap, form]);

    return (
        <Form form={form} style={{ height: 32, maxHeight: 32, marginTop: 4 }}>
            <Form.Item name={`${entity.upc_ean}-checkbox-group`}>
                <Checkbox.Group
                    onChange={(value) => {
                        const upc = entity.upc_ean;
                        setUpcToPlatformMap((sel) => {
                            sel[upc] = value;
                            return sel;
                        });
                    }}
                >
                    <Row style={{ width: 60 }}>
                        {['youtube', 'believe', 'spotify'].map((platform) => (
                            <Col
                                key={`${entity.upc_ean}-${platform}-checkbox-column`}
                                span={8}
                                style={CHECKBOX_COL_STYLE}
                            >
                                <Checkbox
                                    value={platform}
                                    onChange={(e) => {
                                        setSelectedTotals((prev) => {
                                            if (e.target === undefined) {
                                                return prev;
                                            }

                                            const checked = e.target.checked;
                                            const newVals = { ...prev };

                                            if (checked) {
                                                newVals[platform] += 1;
                                            } else {
                                                newVals[platform] -= 1;
                                            }

                                            return newVals;
                                        });
                                    }}
                                />
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            </Form.Item>
        </Form>
    );
};

const ReleasesTable = () => {
    // const [upcToPlatformMap, setUpcToPlatformMap] = useState<upcToPlatformMapType>({});
    const [upcToPlatformMap, setUpcToPlatformMap] = useAtom(upcToPlatformMapAtom);
    const [upcToFormRefMap] = useAtom(upcToFormRefMapAtom);
    const [selectedTotals, setSelectedTotals] = useAtom(selectedTotalsAtom);
    const { releasesActionRef } = useContext(DDEXPageContext);
    const [releasesView] = useAtom(releasesViewAtom);
    const [upcToNameMap, setUpcToNameMap] = useState<{ [k: string]: string }>({});
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        console.log(selectedTotals);
        console.log('youtube selected totals === equality ', selectedTotals.youtube === selectedTotals.total);
        console.log(
            'youtube selected totals double == equality',
            selectedTotals.youtube == selectedTotals.total
        );
    }, [selectedTotals]);

    // const [selected, setSelected] = useState<Array<any>>([]);
    const navigate = useNavigate();
    const location = useLocation();

    const expandedRowRender = (record: API.MusicDistribution.MusicRelease) => {
        const columns: TableColumnsType<API.MusicDistribution.ArtTrack> = [
            // {title: 'Track #', key: '#', render: (_dom, _entity, index) => <Badge count={index + 1}/>},
            {
                title: 'Track #',
                key: '#',
                render: (_dom, _entity, index) => index + 1
            },
            { title: 'Title', dataIndex: 'title', key: 'title' },
            { title: 'ISRC', dataIndex: 'isrc', key: 'isrc' },
            // {title: 'Duration', dataIndex: 'duration', key: 'duration'},
            {
                title: 'Artist',
                dataIndex: 'track_artist',
                key: 'track_artist',
                render: (_text, { main_artists }) =>
                    // track_artist.names.find(({ is_default }) => is_default).full_name
                    main_artists.map((artist) => artist.key_name).join(', ')
            },
            {
                title: 'Featuring Artists',
                dataIndex: 'featuring_artists',
                key: 'featuring_artists',
                render: (_text, { featuring_artists }) =>
                    featuring_artists.map((artist) => artist.key_name).join(', ')
                // featuring_artists
                //     ?.map(({ names }) => names.find(({ is_default }) => is_default).full_name)
                //     .join(', ')
            },
            {
                title: '',
                render: (_dom, track) => {
                    return (
                        track.is_uploaded && (
                            <Tooltip title='Download'>
                                <Button
                                    type='link'
                                    icon={<DownloadOutlined />}
                                    onClick={async () => {
                                        try {
                                            const response = await axiosApiInstance.post(
                                                '/api/products/releases/generate-presigned-url-get',
                                                {
                                                    fileName: track.filename,
                                                    folderName: record.upc_ean
                                                }
                                            );

                                            const { presignedUrl } = response.data;

                                            window.open(presignedUrl, '_blank');
                                        } catch (err) {
                                            console.error('Error downloading file:', err);
                                            message.error('Error downloading file');
                                        }
                                    }}
                                />
                            </Tooltip>
                        )
                    );
                }
            }
        ];

        const data = record.art_tracks ?? [];
        return <Table columns={columns} dataSource={data} pagination={false} rowKey={'isrc'} />;
    };

    const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
                placeholder={`Search Invoice`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type='primary'
                    onClick={() => {
                        confirm();
                    }}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => {
                        clearFilters && clearFilters(); // re ti kanoume
                        setSelectedKeys([]);
                        confirm();
                        close();
                    }}
                    size='small'
                    style={{ width: 90 }}
                >
                    Clear
                </Button>
            </Space>
        </div>
    );
    const filterIcon = (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    );

    const selectAllFunc = (platform: string, checked: boolean) => {
        for (var upc in upcToFormRefMap) {
            const new_list = upcToPlatformMap[upc].filter((p) => p !== platform);

            if (checked) {
                new_list.push(platform);
            }

            upcToFormRefMap[upc]?.setFieldValue(`${upc}-checkbox-group`, new_list);

            setUpcToPlatformMap((m) => {
                m[upc] = new_list;
                return m;
            });
        }
        setSelectedTotals((prev) => {
            const newTotals = { ...prev };
            newTotals[platform] = checked ? prev.total : 0;
            return newTotals;
        });
    };

    const columns: ProColumns<API.MusicDistribution.MusicRelease>[] = [
        {
            title: (
                <Space direction='vertical' style={{ width: 60 }}>
                    <Row style={{ width: 60 }}>
                        {[
                            <FontAwesomeIcon icon={faYoutube} />,
                            <FontAwesomeIcon icon={faB} style={{ marginLeft: 4 }} />,
                            <FontAwesomeIcon icon={faSpotify} style={{ marginLeft: 1 }} />
                        ].map((icon) => (
                            <Col span={8} style={CHECKBOX_COL_STYLE}>
                                {icon}
                            </Col>
                        ))}
                    </Row>
                    <CheckAllCheckbox selectAllFunc={selectAllFunc} />
                </Space>
            ),
            key: 'upload_checks',
            search: false,
            render: (_dom, entity) => <UploaderCheckbox entity={entity} />
        },
        {
            title: '#',
            key: '#',
            search: false,
            render: (_dom, _entity, index) => <Badge count={index + 1} />
        },
        {
            title: 'Album Name',
            dataIndex: 'name',
            key: 'name',
            filterDropdown: filterDropdown,
            filterIcon: filterIcon,
            onFilter: (value, record) => record.name.toLowerCase().includes((value as string).toLowerCase()),
            sorter: (a, b) => a.name.localeCompare(b.name),
            render(_dom, entity, _index, _action, _schema) {
                return (
                    <Link to={`/music/${entity.upc_ean}/view`} state={{ background: location }}>
                        {entity.name}
                    </Link>
                );
            }
        },
        {
            title: 'UPC',
            dataIndex: 'upc_ean',
            key: 'upc',
            filterDropdown: filterDropdown,
            filterIcon: filterIcon,
            onFilter: (value, record) =>
                record.upc_ean.toLowerCase().includes((value as string).toLowerCase())
        },
        {
            title: 'Artist',
            dataIndex: 'main_artist',
            key: 'main_artist',
            renderText: (_text, { artists }) =>
                // main_artist.names.find(({ is_default }) => is_default).full_name
                artists.map((artist) => artist.key_name).join(', ')
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
            renderText: (_text, { label }) => label.name
        },
        {
            title: 'Asset Label',
            dataIndex: 'asset_label',
            key: 'asset_label',
            renderText: (_text, { asset_label }) => asset_label.name
        },
        {
            title: 'Format',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Release Date',
            dataIndex: 'digital_release_date',
            key: 'digital_release_date',
            valueType: 'date',
            sorter: (a, b) => dayjs(a.digital_release_date).diff(dayjs(b.digital_release_date))
        },
        {
            title: 'Upload Status',
            key: 'upload_status',
            hideInTable: releasesView !== 'verified',
            children: [
                {
                    title: 'YouTube',
                    width: 100,
                    align: 'center',
                    key: 'youtube_status',
                    render: (_text, record) => {
                        const log = record.release_upload_status_logs?.find(
                            (log) => log.platform === 'youtube'
                        );
                        return log ? (
                            <Tooltip title={dayjs(log.created_at).format('YYYY-MM-DD HH:mm')}>
                                {UploadStatusBadgeMap[log.status]}
                            </Tooltip>
                        ) : (
                            <Badge status='warning' text={'pending'} />
                        );
                    }
                },
                {
                    title: 'Believe',
                    width: 100,
                    align: 'center',
                    key: 'believe_status',
                    render: (_text, record) => {
                        const log = record.release_upload_status_logs?.find(
                            (log) => log.platform === 'believe'
                        );
                        return log ? (
                            <Tooltip title={dayjs(log.created_at).format('YYYY-MM-DD HH:mm')}>
                                {/* <Badge
                                    status={log.status === 'uploaded' ? 'success' : 'warning'}
                                    text={log.status}
                                /> */}
                                {UploadStatusBadgeMap[log.status]}
                            </Tooltip>
                        ) : (
                            <Badge status='warning' text={'pending'} />
                        );
                    }
                },
                {
                    title: 'Spotify',
                    width: 100,
                    align: 'center',
                    key: 'spotify_status',
                    render: (_text, record) => {
                        const log = record.release_upload_status_logs?.find(
                            (log) => log.platform === 'spotify'
                        );
                        return log ? (
                            <Tooltip title={dayjs(log.created_at).format('YYYY-MM-DD HH:mm')}>
                                {/* <Badge
                                    status={log.status === 'uploaded' ? 'success' : 'warning'}
                                    text={log.status}
                                /> */}
                                {UploadStatusBadgeMap[log.status]}
                            </Tooltip>
                        ) : (
                            <Badge status='warning' text={'pending'} />
                        );
                    }
                }
            ]
        },
        {
            title: '',
            render: (_dom, record) => {
                return (
                    <>
                        {
                            <Row>
                                <Tooltip title='Unverify'>
                                    <Button
                                        type='link'
                                        icon={<StopOutlined />}
                                        onClick={async () => {
                                            await axiosApiInstance
                                                .put(`/api/products/releases/${record.upc_ean}/unverify`)
                                                .catch((reason) => {
                                                    console.error(reason);
                                                });
                                            releasesActionRef.current?.reload();
                                        }}
                                    />
                                </Tooltip>
                                <DownloadBinariesModal
                                    onClick={async () => {
                                        const data = await axiosApiInstance
                                            .get(`/api/products/releases/${record.upc_ean}/binaries`, {
                                                responseType: 'arraybuffer'
                                            })
                                            .then(({ data }) => data);

                                        const url = window.URL.createObjectURL(new Blob([data]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', `${record.upc_ean}.zip`);
                                        document.body.appendChild(link);
                                        link.click();
                                    }}
                                />
                                <Tooltip title='Album Cover'>
                                    <Button
                                        type='link'
                                        icon={<FileImageOutlined />}
                                        onClick={async () => {
                                            try {
                                                const response = await axiosApiInstance.post(
                                                    '/api/products/releases/generate-presigned-url-get',
                                                    {
                                                        fileName: record.album_cover_filename,
                                                        folderName: record.upc_ean
                                                    }
                                                );

                                                const { presignedUrl } = response.data;

                                                window.open(presignedUrl, '_blank');
                                            } catch (err) {
                                                console.error('Error downloading file:', err);
                                                message.error('Error downloading file');
                                            }
                                        }}
                                    />
                                </Tooltip>
                                <UploadRelease
                                    upc_ean={record.upc_ean}
                                    key={`${record.upc_ean}-upload-release-checkbox-group`}
                                />
                                <ModifyRelease
                                    upc_ean={record.upc_ean}
                                    key={`${record.upc_ean}-modify-release-checkbox-group`}
                                />
                                <TakedownRelease
                                    upc_ean={record.upc_ean}
                                    key={`${record.upc_ean}-delete-release-checkbox-group`}
                                />
                            </Row>
                        }
                    </>
                );
            }
        }
    ];

    const minicolumns: TableColumnsType<{ upc: string }> = [
        {
            title: 'UPC',
            dataIndex: 'upc',
            key: 'upc'
        },
        {
            title: 'Title',
            // dataIndex: 'name',
            key: 'title',
            render(_dom, record) {
                return upcToNameMap[record.upc];
            }
        },
        {
            title: <FontAwesomeIcon icon={faYoutube} />,

            render(_dom, record) {
                return upcToPlatformMap[record.upc].includes('youtube') ? <Badge color='blue' /> : <></>;
            }
        },
        {
            title: <FontAwesomeIcon icon={faB} style={{ marginLeft: 4 }} />,
            render(_dom, record) {
                return upcToPlatformMap[record.upc].includes('believe') ? <Badge color='blue' /> : <></>;
            }
        },
        {
            title: <FontAwesomeIcon icon={faSpotify} style={{ marginLeft: 1 }} />,
            render(_dom, record) {
                return upcToPlatformMap[record.upc].includes('spotify') ? <Badge color='blue' /> : <></>;
            }
        }
    ];
    return (
        <ProTable
            columns={columns}
            params={{
                with_label: '',
                with_artists: '',
                with_track_artists: '',
                is_pending: releasesView === 'pending'
            }}
            type='table'
            request={(params) => axiosApiInstance.get(`/api/products/releases/`, { params })}
            toolBarRender={() => [
                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => navigate('/music/new', { state: { background: location } })}
                >
                    Add
                </Button>,
                <Button
                    type='primary'
                    icon={<UploadOutlined />}
                    disabled={selectedTotals.total === 0}
                    onClick={() => setIsOpen(true)}
                >
                    Upload
                </Button>,
                <Modal
                    title='Upload Releases'
                    open={isOpen}
                    destroyOnClose
                    onOk={async () => {
                        const upcs = getSelectedUPCs(upcToPlatformMap);
                        upcs.forEach(async (upc) => {
                            const params = upcToPlatformMap[upc];
                            await axiosApiInstance.put(`/api/products/releases/${upc}/upload`, undefined, {
                                params
                            });
                        });
                    }}
                    onCancel={() => setIsOpen(false)}
                    width={800}
                >
                    <p>Release Details</p>
                    <br />
                    <Table
                        size='small'
                        columns={minicolumns}
                        dataSource={getSelectedUPCs(upcToPlatformMap).map((upc) => ({ upc }))}
                    />
                </Modal>,
                <ExportReleasesExcel />,
                <ExportTemplateExcel />,
                <ImportUpdateReleasesExcel />
            ]}
            rowKey={'upc_ean'}
            search={false}
            headerTitle={<ProCard title='Header' />}
            expandable={{ expandedRowRender }}
            actionRef={releasesActionRef}
            onLoad={(data) => {
                const initMap: upcToPlatformMapType = {};
                const nameMap: { [k: string]: string } = {};
                const records = data.length;
                data.forEach((r) => {
                    initMap[r.upc_ean] = [];
                    nameMap[r.upc_ean] = r.name;
                });
                setUpcToPlatformMap(initMap);
                setUpcToNameMap(nameMap);
                setSelectedTotals((prev) => ({ ...prev, total: records }));
            }}
            toolbar={{
                menu: {
                    // type: 'tab',
                    activeKey: releasesView,
                    items: [
                        {
                            key: 'verified',
                            label: ''
                        }
                        // {
                        //     key: 'pending',
                        //     label: 'Pending'
                        // }
                    ]
                }
            }}
        />
    );
};

export default ReleasesTable;
