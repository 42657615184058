import { ProFormInstance } from '@ant-design/pro-form';
import { atom } from 'jotai';
import { useRef } from 'react';
import { ActionType } from '@ant-design/pro-components';
import { atomWithHash } from 'jotai-location';
import { atomWithQuery, atomWithSuspenseQuery } from 'jotai-tanstack-query';
import axiosApiInstance from '../../../api/axiosClient';

export type entriesAtomType = {
    album_name: string;
    upc: string;
    label_id: number;
    main_artist_id: number;
    number_of_tracks: number;
    initial_track_array: Array<any>;
};

export const releaseEntriesAtom = atom<entriesAtomType>({
    album_name: '',
    upc: '',
    label_id: 1,
    main_artist_id: 1,
    number_of_tracks: 1,
    initial_track_array: Array(1)
});

// const formRef = useRef<ProFormInstance<entriesAtomType>>(null);

export const formRefAtom = atom((read) => useRef<ProFormInstance<any>>());

export const languageOptions = [
    {
        label: '🇬🇷 Greek',
        value: 'el'
    },
    {
        label: '🇬🇧 English',
        value: 'en'
    }
];

export const labelOptions = [
    {
        label: 'Panik Records',
        value: 1
    },
    {
        label: 'MelodyMaker',
        value: 2
    }
];

export const artistOptions = [
    {
        label: 'Snik',
        value: 1
    },
    {
        label: 'Lefteris Pantazis',
        value: 2
    },
    {
        label: 'Josephine',
        value: 3
    }
];

export const countryCodes = [
    'AF',
    'AM',
    'AZ',
    'BD',
    'BH',
    'BT',
    'BN',
    'KH',
    'CN',
    'GE',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IL',
    'JP',
    'JO',
    'KZ',
    'KW',
    'KG',
    'LA',
    'LB',
    'MY',
    'MV',
    'MN',
    'MM',
    'NP',
    'KP',
    'OM',
    'PK',
    'PS',
    'PH',
    'QA',
    'SA',
    'SG',
    'KR',
    'LK',
    'SY',
    'TW',
    'TJ',
    'TH',
    'TL',
    'TM',
    'AE',
    'UZ',
    'VN',
    'YE',
    'AL',
    'AD',
    'AT',
    'BY',
    'BE',
    'BA',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'XK',
    'LV',
    'LI',
    'LT',
    'LU',
    'MT',
    'MD',
    'MC',
    'ME',
    'NL',
    'MK',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'SM',
    'RS',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'UA',
    'GB',
    'VA',
    'DZ',
    'AO',
    'BJ',
    'BW',
    'BF',
    'BI',
    'CV',
    'CM',
    'CF',
    'TD',
    'KM',
    'CG',
    'CD',
    'CI',
    'DJ',
    'EG',
    'GQ',
    'ER',
    'SZ',
    'ET',
    'GA',
    'GM',
    'GH',
    'GN',
    'GW',
    'KE',
    'LS',
    'LR',
    'LY',
    'MG',
    'MW',
    'ML',
    'MR',
    'MU',
    'MA',
    'MZ',
    'NA',
    'NE',
    'NG',
    'RW',
    'ST',
    'SN',
    'SC',
    'SL',
    'SO',
    'ZA',
    'SS',
    'SD',
    'TZ',
    'TG',
    'TN',
    'UG',
    'ZM',
    'ZW',
    'AU',
    'FJ',
    'KI',
    'MH',
    'FM',
    'NR',
    'NZ',
    'PW',
    'PG',
    'WS',
    'SB',
    'TO',
    'TV',
    'VU',
    'AG',
    'BS',
    'BB',
    'BZ',
    'CA',
    'CR',
    'CU',
    'DM',
    'DO',
    'SV',
    'GD',
    'GT',
    'HT',
    'HN',
    'JM',
    'MX',
    'NI',
    'PA',
    'KN',
    'LC',
    'VC',
    'TT',
    'US',
    'AR',
    'BO',
    'BR',
    'CL',
    'CO',
    'EC',
    'GY',
    'PY',
    'PE',
    'SR',
    'UY',
    'VE',
    'AQ'
];

export const countryTreeOptions = [
    {
        value: 'Worldwide',
        title: '🌎 World Wide',
        children: [
            {
                // value: 'Asia',
                value: 'AF,AM,AZ,BD,BH,BT,BN,KH,CN,GE,IN,ID,IR,IQ,IL,JP,JO,KZ,KW,KG,LA,LB,MY,MV,MN,MM,NP,KP,OM,PK,PS,PH,QA,SA,SG,KR,LK,SY,TW,TJ,TH,TL,TM,AE,UZ,VN,YE',
                key: 'AF,AM,AZ,BD,BH,BT,BN,KH,CN,GE,IN,ID,IR,IQ,IL,JP,JO,KZ,KW,KG,LA,LB,MY,MV,MN,MM,NP,KP,OM,PK,PS,PH,QA,SA,SG,KR,LK,SY,TW,TJ,TH,TL,TM,AE,UZ,VN,YE',
                title: 'Asia',
                children: [
                    { label: '🇦🇫 Afghanistan', value: 'AF' },
                    { label: '🇦🇲 Armenia', value: 'AM' },
                    { label: '🇦🇿 Azerbaijan', value: 'AZ' },
                    { label: '🇧🇩 Bangladesh', value: 'BD' },
                    { label: '🇧🇭 Bahrain', value: 'BH' },
                    { label: '🇧🇹 Bhutan', value: 'BT' },
                    { label: '🇧🇳 Brunei', value: 'BN' },
                    { label: '🇰🇭 Cambodia', value: 'KH' },
                    { label: '🇨🇳 China', value: 'CN' },
                    { label: '🇬🇪 Georgia', value: 'GE' },
                    { label: '🇮🇳 India', value: 'IN' },
                    { label: '🇮🇩 Indonesia', value: 'ID' },
                    { label: '🇮🇷 Iran', value: 'IR' },
                    { label: '🇮🇶 Iraq', value: 'IQ' },
                    { label: '🇮🇱 Israel', value: 'IL' },
                    { label: '🇯🇵 Japan', value: 'JP' },
                    { label: '🇯🇴 Jordan', value: 'JO' },
                    { label: '🇰🇿 Kazakhstan', value: 'KZ' },
                    { label: '🇰🇼 Kuwait', value: 'KW' },
                    { label: '🇰🇬 Kyrgyzstan', value: 'KG' },
                    { label: '🇱🇦 Laos', value: 'LA' },
                    { label: '🇱🇧 Lebanon', value: 'LB' },
                    { label: '🇲🇾 Malaysia', value: 'MY' },
                    { label: '🇲🇻 Maldives', value: 'MV' },
                    { label: '🇲🇳 Mongolia', value: 'MN' },
                    { label: '🇲🇲 Myanmar', value: 'MM' },
                    { label: '🇳🇵 Nepal', value: 'NP' },
                    { label: '🇰🇵 North Korea', value: 'KP' },
                    { label: '🇴🇲 Oman', value: 'OM' },
                    { label: '🇵🇰 Pakistan', value: 'PK' },
                    { label: '🇵🇸 Palestine', value: 'PS' },
                    { label: '🇵🇭 Philippines', value: 'PH' },
                    { label: '🇶🇦 Qatar', value: 'QA' },
                    { label: '🇸🇦 Saudi Arabia', value: 'SA' },
                    { label: '🇸🇬 Singapore', value: 'SG' },
                    { label: '🇰🇷 South Korea', value: 'KR' },
                    { label: '🇱🇰 Sri Lanka', value: 'LK' },
                    { label: '🇸🇾 Syria', value: 'SY' },
                    { label: '🇹🇼 Taiwan', value: 'TW' },
                    { label: '🇹🇯 Tajikistan', value: 'TJ' },
                    { label: '🇹🇭 Thailand', value: 'TH' },
                    { label: '🇹🇱 Timor-Leste', value: 'TL' },
                    { label: '🇹🇲 Turkmenistan', value: 'TM' },
                    { label: '🇦🇪 United Arab Emirates', value: 'AE' },
                    { label: '🇺🇿 Uzbekistan', value: 'UZ' },
                    { label: '🇻🇳 Vietnam', value: 'VN' },
                    { label: '🇾🇪 Yemen', value: 'YE' }
                ]
            },
            {
                // value: 'Europe',
                value: 'AL,AD,AT,BY,BE,BA,BG,HR,CY,CZ,DK,EE,FI,FR,DE,GR,HU,IS,IE,IT,XK,LV,LI,LT,LU,MT,MD,MC,ME,NL,MK,NO,PL,PT,RO,RU,SM,RS,SK,SI,ES,SE,CH,UA,GB,VA',
                key: 'AL,AD,AT,BY,BE,BA,BG,HR,CY,CZ,DK,EE,FI,FR,DE,GR,HU,IS,IE,IT,XK,LV,LI,LT,LU,MT,MD,MC,ME,NL,MK,NO,PL,PT,RO,RU,SM,RS,SK,SI,ES,SE,CH,UA,GB,VA',
                title: 'Europe',
                children: [
                    { label: '🇦🇱 Albania', value: 'AL' },
                    { label: '🇦🇩 Andorra', value: 'AD' },
                    { label: '🇦🇹 Austria', value: 'AT' },
                    { label: '🇧🇾 Belarus', value: 'BY' },
                    { label: '🇧🇪 Belgium', value: 'BE' },
                    { label: '🇧🇦 Bosnia and Herzegovina', value: 'BA' },
                    { label: '🇧🇬 Bulgaria', value: 'BG' },
                    { label: '🇭🇷 Croatia', value: 'HR' },
                    { label: '🇨🇾 Cyprus', value: 'CY' },
                    { label: '🇨🇿 Czech Republic', value: 'CZ' },
                    { label: '🇩🇰 Denmark', value: 'DK' },
                    { label: '🇪🇪 Estonia', value: 'EE' },
                    { label: '🇫🇮 Finland', value: 'FI' },
                    { label: '🇫🇷 France', value: 'FR' },
                    { label: '🇩🇪 Germany', value: 'DE' },
                    { label: '🇬🇷 Greece', value: 'GR' },
                    { label: '🇭🇺 Hungary', value: 'HU' },
                    { label: '🇮🇸 Iceland', value: 'IS' },
                    { label: '🇮🇪 Ireland', value: 'IE' },
                    { label: '🇮🇹 Italy', value: 'IT' },
                    { label: '🇽🇰 Kosovo', value: 'XK' },
                    { label: '🇱🇻 Latvia', value: 'LV' },
                    { label: '🇱🇮 Liechtenstein', value: 'LI' },
                    { label: '🇱🇹 Lithuania', value: 'LT' },
                    { label: '🇱🇺 Luxembourg', value: 'LU' },
                    { label: '🇲🇹 Malta', value: 'MT' },
                    { label: '🇲🇩 Moldova', value: 'MD' },
                    { label: '🇲🇨 Monaco', value: 'MC' },
                    { label: '🇲🇪 Montenegro', value: 'ME' },
                    { label: '🇳🇱 Netherlands', value: 'NL' },
                    { label: '🇲🇰 North Macedonia', value: 'MK' },
                    { label: '🇳🇴 Norway', value: 'NO' },
                    { label: '🇵🇱 Poland', value: 'PL' },
                    { label: '🇵🇹 Portugal', value: 'PT' },
                    { label: '🇷🇴 Romania', value: 'RO' },
                    { label: '🇷🇺 Russia', value: 'RU' },
                    { label: '🇸🇲 San Marino', value: 'SM' },
                    { label: '🇷🇸 Serbia', value: 'RS' },
                    { label: '🇸🇰 Slovakia', value: 'SK' },
                    { label: '🇸🇮 Slovenia', value: 'SI' },
                    { label: '🇪🇸 Spain', value: 'ES' },
                    { label: '🇸🇪 Sweden', value: 'SE' },
                    { label: '🇨🇭 Switzerland', value: 'CH' },
                    { label: '🇺🇦 Ukraine', value: 'UA' },
                    { label: '🇬🇧 United Kingdom', value: 'GB' },
                    { label: '🇻🇦 Vatican City', value: 'VA' }
                ]
            },
            {
                // value: 'Africa',
                value: 'DZ,AO,BJ,BW,BF,BI,CV,CM,CF,TD,KM,CG,CD,CI,DJ,EG,GQ,ER,SZ,ET,GA,GM,GH,GN,GW,KE,LS,LR,LY,MG,MW,ML,MR,MU,MA,MZ,NA,NE,NG,RW,ST,SN,SC,SL,SO,ZA,SS,SD,TZ,TG,TN,UG,ZM,ZW',
                title: 'Africa',
                children: [
                    { label: '🇩🇿 Algeria', value: 'DZ' },
                    { label: '🇦🇴 Angola', value: 'AO' },
                    { label: '🇧🇯 Benin', value: 'BJ' },
                    { label: '🇧🇼 Botswana', value: 'BW' },
                    { label: '🇧🇫 Burkina Faso', value: 'BF' },
                    { label: '🇧🇮 Burundi', value: 'BI' },
                    { label: '🇨🇻 Cape Verde', value: 'CV' },
                    { label: '🇨🇲 Cameroon', value: 'CM' },
                    { label: '🇨🇫 Central African Republic', value: 'CF' },
                    { label: '🇹🇩 Chad', value: 'TD' },
                    { label: '🇰🇲 Comoros', value: 'KM' },
                    { label: '🇨🇬 Congo', value: 'CG' },
                    { label: '🇨🇩 Democratic Republic of the Congo', value: 'CD' },
                    { label: "🇨🇮 Côte d'Ivoire", value: 'CI' },
                    { label: '🇩🇯 Djibouti', value: 'DJ' },
                    { label: '🇪🇬 Egypt', value: 'EG' },
                    { label: '🇬🇶 Equatorial Guinea', value: 'GQ' },
                    { label: '🇪🇷 Eritrea', value: 'ER' },
                    { label: '🇸🇿 Eswatini', value: 'SZ' },
                    { label: '🇪🇹 Ethiopia', value: 'ET' },
                    { label: '🇬🇦 Gabon', value: 'GA' },
                    { label: '🇬🇲 Gambia', value: 'GM' },
                    { label: '🇬🇭 Ghana', value: 'GH' },
                    { label: '🇬🇳 Guinea', value: 'GN' },
                    { label: '🇬🇼 Guinea-Bissau', value: 'GW' },
                    { label: '🇰🇪 Kenya', value: 'KE' },
                    { label: '🇱🇸 Lesotho', value: 'LS' },
                    { label: '🇱🇷 Liberia', value: 'LR' },
                    { label: '🇱🇾 Libya', value: 'LY' },
                    { label: '🇲🇬 Madagascar', value: 'MG' },
                    { label: '🇲🇼 Malawi', value: 'MW' },
                    { label: '🇲🇱 Mali', value: 'ML' },
                    { label: '🇲🇷 Mauritania', value: 'MR' },
                    { label: '🇲🇺 Mauritius', value: 'MU' },
                    { label: '🇲🇦 Morocco', value: 'MA' },
                    { label: '🇲🇿 Mozambique', value: 'MZ' },
                    { label: '🇳🇦 Namibia', value: 'NA' },
                    { label: '🇳🇪 Niger', value: 'NE' },
                    { label: '🇳🇬 Nigeria', value: 'NG' },
                    { label: '🇷🇼 Rwanda', value: 'RW' },
                    { label: '🇸🇹 São Tomé and Príncipe', value: 'ST' },
                    { label: '🇸🇳 Senegal', value: 'SN' },
                    { label: '🇸🇨 Seychelles', value: 'SC' },
                    { label: '🇸🇱 Sierra Leone', value: 'SL' },
                    { label: '🇸🇴 Somalia', value: 'SO' },
                    { label: '🇿🇦 South Africa', value: 'ZA' },
                    { label: '🇸🇸 South Sudan', value: 'SS' },
                    { label: '🇸🇩 Sudan', value: 'SD' },
                    { label: '🇹🇿 Tanzania', value: 'TZ' },
                    { label: '🇹🇬 Togo', value: 'TG' },
                    { label: '🇹🇳 Tunisia', value: 'TN' },
                    { label: '🇺🇬 Uganda', value: 'UG' },
                    { label: '🇿🇲 Zambia', value: 'ZM' },
                    { label: '🇿🇼 Zimbabwe', value: 'ZW' }
                ]
            },
            {
                // value: 'Australia',
                value: 'AU,FJ,KI,MH,FM,NR,NZ,PW,PG,WS,SB,TO,TV,VU',
                key: 'AU,FJ,KI,MH,FM,NR,NZ,PW,PG,WS,SB,TO,TV,VU',
                title: 'Australia',
                children: [
                    { label: '🇦🇺 Australia', value: 'AU' },
                    { label: '🇫🇯 Fiji', value: 'FJ' },
                    { label: '🇰🇮 Kiribati', value: 'KI' },
                    { label: '🇲🇭 Marshall Islands', value: 'MH' },
                    { label: '🇫🇲 Micronesia', value: 'FM' },
                    { label: '🇳🇷 Nauru', value: 'NR' },
                    { label: '🇳🇿 New Zealand', value: 'NZ' },
                    { label: '🇵🇼 Palau', value: 'PW' },
                    { label: '🇵🇬 Papua New Guinea', value: 'PG' },
                    { label: '🇼🇸 Samoa', value: 'WS' },
                    { label: '🇸🇧 Solomon Islands', value: 'SB' },
                    { label: '🇹🇴 Tonga', value: 'TO' },
                    { label: '🇹🇻 Tuvalu', value: 'TV' },
                    { label: '🇻🇺 Vanuatu', value: 'VU' }
                ]
            },
            {
                // value: 'North America',
                value: 'AG,BS,BB,BZ,CA,CR,CU,DM,DO,SV,GD,GT,HT,HN,JM,MX,NI,PA,KN,LC,VC,TT,US',
                key: 'AG,BS,BB,BZ,CA,CR,CU,DM,DO,SV,GD,GT,HT,HN,JM,MX,NI,PA,KN,LC,VC,TT,US',
                title: 'North America',
                children: [
                    { label: '🇦🇬 Antigua and Barbuda', value: 'AG' },
                    { label: '🇧🇸 Bahamas', value: 'BS' },
                    { label: '🇧🇧 Barbados', value: 'BB' },
                    { label: '🇧🇿 Belize', value: 'BZ' },
                    { label: '🇨🇦 Canada', value: 'CA' },
                    { label: '🇨🇷 Costa Rica', value: 'CR' },
                    { label: '🇨🇺 Cuba', value: 'CU' },
                    { label: '🇩🇲 Dominica', value: 'DM' },
                    { label: '🇩🇴 Dominican Republic', value: 'DO' },
                    { label: '🇸🇻 El Salvador', value: 'SV' },
                    { label: '🇬🇩 Grenada', value: 'GD' },
                    { label: '🇬🇹 Guatemala', value: 'GT' },
                    { label: '🇭🇹 Haiti', value: 'HT' },
                    { label: '🇭🇳 Honduras', value: 'HN' },
                    { label: '🇯🇲 Jamaica', value: 'JM' },
                    { label: '🇲🇽 Mexico', value: 'MX' },
                    { label: '🇳🇮 Nicaragua', value: 'NI' },
                    { label: '🇵🇦 Panama', value: 'PA' },
                    { label: '🇰🇳 Saint Kitts and Nevis', value: 'KN' },
                    { label: '🇱🇨 Saint Lucia', value: 'LC' },
                    { label: '🇻🇨 Saint Vincent and the Grenadines', value: 'VC' },
                    { label: '🇹🇹 Trinidad and Tobago', value: 'TT' },
                    { label: '🇺🇸 United States', value: 'US' }
                ]
            },
            {
                // value: 'South America',
                value: 'AR,BO,BR,CL,CO,EC,GY,PY,PE,SR,UY,VE',
                key: 'AR,BO,BR,CL,CO,EC,GY,PY,PE,SR,UY,VE',
                title: 'South America',
                children: [
                    { label: '🇦🇷 Argentina', value: 'AR' },
                    { label: '🇧🇴 Bolivia', value: 'BO' },
                    { label: '🇧🇷 Brazil', value: 'BR' },
                    { label: '🇨🇱 Chile', value: 'CL' },
                    { label: '🇨🇴 Colombia', value: 'CO' },
                    { label: '🇪🇨 Ecuador', value: 'EC' },
                    { label: '🇬🇾 Guyana', value: 'GY' },
                    { label: '🇵🇾 Paraguay', value: 'PY' },
                    { label: '🇵🇪 Peru', value: 'PE' },
                    { label: '🇸🇷 Suriname', value: 'SR' },
                    { label: '🇺🇾 Uruguay', value: 'UY' },
                    { label: '🇻🇪 Venezuela', value: 'VE' }
                ]
            },
            {
                // value: 'Antarctica',
                value: 'AQ',
                title: 'Antarctica'
                // children: [{ label: '🇦🇶 Antarctica', value: 'AQ' }]
            }
        ]
    }
];

export const countryOptions = [
    {
        label: '🌎 World Wide',
        title: 'WorldWide',
        value: 'WW'
    },
    {
        label: 'Asia',
        title: 'Asia',
        options: [
            { label: '🇦🇫 Afghanistan', value: 'AF' },
            { label: '🇦🇲 Armenia', value: 'AM' },
            { label: '🇦🇿 Azerbaijan', value: 'AZ' },
            { label: '🇧🇩 Bangladesh', value: 'BD' },
            { label: '🇧🇭 Bahrain', value: 'BH' },
            { label: '🇧🇹 Bhutan', value: 'BT' },
            { label: '🇧🇳 Brunei', value: 'BN' },
            { label: '🇰🇭 Cambodia', value: 'KH' },
            { label: '🇨🇳 China', value: 'CN' },
            { label: '🇬🇪 Georgia', value: 'GE' },
            { label: '🇮🇳 India', value: 'IN' },
            { label: '🇮🇩 Indonesia', value: 'ID' },
            { label: '🇮🇷 Iran', value: 'IR' },
            { label: '🇮🇶 Iraq', value: 'IQ' },
            { label: '🇮🇱 Israel', value: 'IL' },
            { label: '🇯🇵 Japan', value: 'JP' },
            { label: '🇯🇴 Jordan', value: 'JO' },
            { label: '🇰🇿 Kazakhstan', value: 'KZ' },
            { label: '🇰🇼 Kuwait', value: 'KW' },
            { label: '🇰🇬 Kyrgyzstan', value: 'KG' },
            { label: '🇱🇦 Laos', value: 'LA' },
            { label: '🇱🇧 Lebanon', value: 'LB' },
            { label: '🇲🇾 Malaysia', value: 'MY' },
            { label: '🇲🇻 Maldives', value: 'MV' },
            { label: '🇲🇳 Mongolia', value: 'MN' },
            { label: '🇲🇲 Myanmar', value: 'MM' },
            { label: '🇳🇵 Nepal', value: 'NP' },
            { label: '🇰🇵 North Korea', value: 'KP' },
            { label: '🇴🇲 Oman', value: 'OM' },
            { label: '🇵🇰 Pakistan', value: 'PK' },
            { label: '🇵🇸 Palestine', value: 'PS' },
            { label: '🇵🇭 Philippines', value: 'PH' },
            { label: '🇶🇦 Qatar', value: 'QA' },
            { label: '🇸🇦 Saudi Arabia', value: 'SA' },
            { label: '🇸🇬 Singapore', value: 'SG' },
            { label: '🇰🇷 South Korea', value: 'KR' },
            { label: '🇱🇰 Sri Lanka', value: 'LK' },
            { label: '🇸🇾 Syria', value: 'SY' },
            { label: '🇹🇼 Taiwan', value: 'TW' },
            { label: '🇹🇯 Tajikistan', value: 'TJ' },
            { label: '🇹🇭 Thailand', value: 'TH' },
            { label: '🇹🇱 Timor-Leste', value: 'TL' },
            { label: '🇹🇲 Turkmenistan', value: 'TM' },
            { label: '🇦🇪 United Arab Emirates', value: 'AE' },
            { label: '🇺🇿 Uzbekistan', value: 'UZ' },
            { label: '🇻🇳 Vietnam', value: 'VN' },
            { label: '🇾🇪 Yemen', value: 'YE' }
        ]
    },
    {
        label: 'Europe',
        title: 'Europe',
        options: [
            { label: '🇦🇱 Albania', value: 'AL' },
            { label: '🇦🇩 Andorra', value: 'AD' },
            { label: '🇦🇹 Austria', value: 'AT' },
            { label: '🇧🇾 Belarus', value: 'BY' },
            { label: '🇧🇪 Belgium', value: 'BE' },
            { label: '🇧🇦 Bosnia and Herzegovina', value: 'BA' },
            { label: '🇧🇬 Bulgaria', value: 'BG' },
            { label: '🇭🇷 Croatia', value: 'HR' },
            { label: '🇨🇾 Cyprus', value: 'CY' },
            { label: '🇨🇿 Czech Republic', value: 'CZ' },
            { label: '🇩🇰 Denmark', value: 'DK' },
            { label: '🇪🇪 Estonia', value: 'EE' },
            { label: '🇫🇮 Finland', value: 'FI' },
            { label: '🇫🇷 France', value: 'FR' },
            { label: '🇩🇪 Germany', value: 'DE' },
            { label: '🇬🇷 Greece', value: 'GR' },
            { label: '🇭🇺 Hungary', value: 'HU' },
            { label: '🇮🇸 Iceland', value: 'IS' },
            { label: '🇮🇪 Ireland', value: 'IE' },
            { label: '🇮🇹 Italy', value: 'IT' },
            { label: '🇽🇰 Kosovo', value: 'XK' },
            { label: '🇱🇻 Latvia', value: 'LV' },
            { label: '🇱🇮 Liechtenstein', value: 'LI' },
            { label: '🇱🇹 Lithuania', value: 'LT' },
            { label: '🇱🇺 Luxembourg', value: 'LU' },
            { label: '🇲🇹 Malta', value: 'MT' },
            { label: '🇲🇩 Moldova', value: 'MD' },
            { label: '🇲🇨 Monaco', value: 'MC' },
            { label: '🇲🇪 Montenegro', value: 'ME' },
            { label: '🇳🇱 Netherlands', value: 'NL' },
            { label: '🇲🇰 North Macedonia', value: 'MK' },
            { label: '🇳🇴 Norway', value: 'NO' },
            { label: '🇵🇱 Poland', value: 'PL' },
            { label: '🇵🇹 Portugal', value: 'PT' },
            { label: '🇷🇴 Romania', value: 'RO' },
            { label: '🇷🇺 Russia', value: 'RU' },
            { label: '🇸🇲 San Marino', value: 'SM' },
            { label: '🇷🇸 Serbia', value: 'RS' },
            { label: '🇸🇰 Slovakia', value: 'SK' },
            { label: '🇸🇮 Slovenia', value: 'SI' },
            { label: '🇪🇸 Spain', value: 'ES' },
            { label: '🇸🇪 Sweden', value: 'SE' },
            { label: '🇨🇭 Switzerland', value: 'CH' },
            { label: '🇺🇦 Ukraine', value: 'UA' },
            { label: '🇬🇧 United Kingdom', value: 'GB' },
            { label: '🇻🇦 Vatican City', value: 'VA' }
        ]
    },
    {
        label: 'Africa',
        title: 'Africa',
        options: [
            { label: '🇩🇿 Algeria', value: 'DZ' },
            { label: '🇦🇴 Angola', value: 'AO' },
            { label: '🇧🇯 Benin', value: 'BJ' },
            { label: '🇧🇼 Botswana', value: 'BW' },
            { label: '🇧🇫 Burkina Faso', value: 'BF' },
            { label: '🇧🇮 Burundi', value: 'BI' },
            { label: '🇨🇻 Cape Verde', value: 'CV' },
            { label: '🇨🇲 Cameroon', value: 'CM' },
            { label: '🇨🇫 Central African Republic', value: 'CF' },
            { label: '🇹🇩 Chad', value: 'TD' },
            { label: '🇰🇲 Comoros', value: 'KM' },
            { label: '🇨🇬 Congo', value: 'CG' },
            { label: '🇨🇩 Democratic Republic of the Congo', value: 'CD' },
            { label: "🇨🇮 Côte d'Ivoire", value: 'CI' },
            { label: '🇩🇯 Djibouti', value: 'DJ' },
            { label: '🇪🇬 Egypt', value: 'EG' },
            { label: '🇬🇶 Equatorial Guinea', value: 'GQ' },
            { label: '🇪🇷 Eritrea', value: 'ER' },
            { label: '🇸🇿 Eswatini', value: 'SZ' },
            { label: '🇪🇹 Ethiopia', value: 'ET' },
            { label: '🇬🇦 Gabon', value: 'GA' },
            { label: '🇬🇲 Gambia', value: 'GM' },
            { label: '🇬🇭 Ghana', value: 'GH' },
            { label: '🇬🇳 Guinea', value: 'GN' },
            { label: '🇬🇼 Guinea-Bissau', value: 'GW' },
            { label: '🇰🇪 Kenya', value: 'KE' },
            { label: '🇱🇸 Lesotho', value: 'LS' },
            { label: '🇱🇷 Liberia', value: 'LR' },
            { label: '🇱🇾 Libya', value: 'LY' },
            { label: '🇲🇬 Madagascar', value: 'MG' },
            { label: '🇲🇼 Malawi', value: 'MW' },
            { label: '🇲🇱 Mali', value: 'ML' },
            { label: '🇲🇷 Mauritania', value: 'MR' },
            { label: '🇲🇺 Mauritius', value: 'MU' },
            { label: '🇲🇦 Morocco', value: 'MA' },
            { label: '🇲🇿 Mozambique', value: 'MZ' },
            { label: '🇳🇦 Namibia', value: 'NA' },
            { label: '🇳🇪 Niger', value: 'NE' },
            { label: '🇳🇬 Nigeria', value: 'NG' },
            { label: '🇷🇼 Rwanda', value: 'RW' },
            { label: '🇸🇹 São Tomé and Príncipe', value: 'ST' },
            { label: '🇸🇳 Senegal', value: 'SN' },
            { label: '🇸🇨 Seychelles', value: 'SC' },
            { label: '🇸🇱 Sierra Leone', value: 'SL' },
            { label: '🇸🇴 Somalia', value: 'SO' },
            { label: '🇿🇦 South Africa', value: 'ZA' },
            { label: '🇸🇸 South Sudan', value: 'SS' },
            { label: '🇸🇩 Sudan', value: 'SD' },
            { label: '🇹🇿 Tanzania', value: 'TZ' },
            { label: '🇹🇬 Togo', value: 'TG' },
            { label: '🇹🇳 Tunisia', value: 'TN' },
            { label: '🇺🇬 Uganda', value: 'UG' },
            { label: '🇿🇲 Zambia', value: 'ZM' },
            { label: '🇿🇼 Zimbabwe', value: 'ZW' }
        ]
    },
    {
        label: 'Australia',
        title: 'Australia',
        options: [
            { label: '🇦🇺 Australia', value: 'AU' },
            { label: '🇫🇯 Fiji', value: 'FJ' },
            { label: '🇰🇮 Kiribati', value: 'KI' },
            { label: '🇲🇭 Marshall Islands', value: 'MH' },
            { label: '🇫🇲 Micronesia', value: 'FM' },
            { label: '🇳🇷 Nauru', value: 'NR' },
            { label: '🇳🇿 New Zealand', value: 'NZ' },
            { label: '🇵🇼 Palau', value: 'PW' },
            { label: '🇵🇬 Papua New Guinea', value: 'PG' },
            { label: '🇼🇸 Samoa', value: 'WS' },
            { label: '🇸🇧 Solomon Islands', value: 'SB' },
            { label: '🇹🇴 Tonga', value: 'TO' },
            { label: '🇹🇻 Tuvalu', value: 'TV' },
            { label: '🇻🇺 Vanuatu', value: 'VU' }
        ]
    },
    {
        label: 'North America',
        title: 'North America',
        options: [
            { label: '🇦🇬 Antigua and Barbuda', value: 'AG' },
            { label: '🇧🇸 Bahamas', value: 'BS' },
            { label: '🇧🇧 Barbados', value: 'BB' },
            { label: '🇧🇿 Belize', value: 'BZ' },
            { label: '🇨🇦 Canada', value: 'CA' },
            { label: '🇨🇷 Costa Rica', value: 'CR' },
            { label: '🇨🇺 Cuba', value: 'CU' },
            { label: '🇩🇲 Dominica', value: 'DM' },
            { label: '🇩🇴 Dominican Republic', value: 'DO' },
            { label: '🇸🇻 El Salvador', value: 'SV' },
            { label: '🇬🇩 Grenada', value: 'GD' },
            { label: '🇬🇹 Guatemala', value: 'GT' },
            { label: '🇭🇹 Haiti', value: 'HT' },
            { label: '🇭🇳 Honduras', value: 'HN' },
            { label: '🇯🇲 Jamaica', value: 'JM' },
            { label: '🇲🇽 Mexico', value: 'MX' },
            { label: '🇳🇮 Nicaragua', value: 'NI' },
            { label: '🇵🇦 Panama', value: 'PA' },
            { label: '🇰🇳 Saint Kitts and Nevis', value: 'KN' },
            { label: '🇱🇨 Saint Lucia', value: 'LC' },
            { label: '🇻🇨 Saint Vincent and the Grenadines', value: 'VC' },
            { label: '🇹🇹 Trinidad and Tobago', value: 'TT' },
            { label: '🇺🇸 United States', value: 'US' }
        ]
    },
    {
        label: 'South America',
        title: 'South America',
        options: [
            { label: '🇦🇷 Argentina', value: 'AR' },
            { label: '🇧🇴 Bolivia', value: 'BO' },
            { label: '🇧🇷 Brazil', value: 'BR' },
            { label: '🇨🇱 Chile', value: 'CL' },
            { label: '🇨🇴 Colombia', value: 'CO' },
            { label: '🇪🇨 Ecuador', value: 'EC' },
            { label: '🇬🇾 Guyana', value: 'GY' },
            { label: '🇵🇾 Paraguay', value: 'PY' },
            { label: '🇵🇪 Peru', value: 'PE' },
            { label: '🇸🇷 Suriname', value: 'SR' },
            { label: '🇺🇾 Uruguay', value: 'UY' },
            { label: '🇻🇪 Venezuela', value: 'VE' }
        ]
    },
    {
        label: 'Antarctica',
        title: 'Antarctica',
        options: [{ label: '🇦🇶 Antarctica', value: 'AQ' }]
    }
];

export const genreOptions = [
    'African',
    'Alternative',
    'Arabic',
    'Asian',
    'Blues',
    'Brazilian',
    'Children Music',
    'Christian & Gospel',
    'Classical',
    'Country',
    'Dance',
    'Easy Listening',
    'Electronic',
    'Folk',
    'Hip Hop/Rap',
    'Indian',
    'Jazz',
    'Latin',
    'Metal',
    'Pop',
    'R&B/Soul',
    'Reggae',
    'Relaxation',
    'Rock',
    'Various',
    'World Music / Regional Folklore'
];

export const genreToSubgenreOptions = {
    African: [
        'Afrikaans',
        'Afro Bashment',
        'Afro Pop',
        'Afro Soul',
        'Afro Trap',
        'Afro Urbain',
        'Afrobeat',
        'Assiko',
        'Bend-Skin',
        'Benga',
        'Bikutsi',
        'Bongo-Flava',
        'Coup\u00e9 D\u00e9cal\u00e9',
        'Gospel',
        'Gqom',
        'Highlife / Hiplife',
        'J\u00f9j\u00fa music',
        'Kizomba',
        'Kuduro',
        'Kwaito',
        'Makossa',
        'Mandingue',
        'Maskandi',
        'Mbalax',
        'Ndombolo',
        'RPK / Rap',
        'Rumba Congolaise',
        'Shangaan Electro',
        'Soukouss',
        'Taarab',
        'Yoruba',
        'Zouglou'
    ],
    Alternative: [
        'Electronic / EBM',
        'Emo Punk',
        'Gothic',
        'Grunge',
        'Indie Pop',
        'Indie Rock',
        'Latin',
        'Latin Ska',
        'New Wave',
        'Pop Punk',
        'Post Punk',
        'Punk',
        'Ska'
    ],
    Arabic: [
        'Algerian Ra\u00ef',
        'Amazigh',
        'Arabesque',
        'Egyptian Chaabiat',
        'Egyptian Folk El Kaf Aswani',
        'Egyptian Folk Nubian',
        'Egyptian Folk Saidi',
        'Egyptian Indie',
        'Egyptian Mahraganat',
        'Egyptian Musicals',
        'Egyptian Operette',
        'Egyptian Patriotic',
        'Egyptian Plays',
        'Egyptian Satire',
        'Egyptian Soundtrack',
        'Egyptian Tarab Classical',
        'Egyptian Tarab Pop',
        'Egyptian TV Shows',
        'Electronic Dance Music',
        'Indie',
        'Iraqi Folk Dabka',
        'Iraqi Folk Khachaba',
        'Iraqi Folk Maqam',
        'Iraqi Folk Mawaweel',
        'Iraqi Folk Radeh',
        'Iraqi Indie',
        'Iraqi Musicals',
        'Iraqi Operette',
        'Iraqi Patriotic',
        'Iraqi Plays',
        'Iraqi Satire',
        'Iraqi Soundtrack',
        'Iraqi Tarab Classical',
        'Iraqi Tarab Pop',
        'Iraqi TV Shows',
        'Islamic',
        'Islamic Anasheed',
        'Islamic Chant',
        'Islamic Quran',
        'Islamic Ramadan',
        'Islamic Recital',
        'Islamic Speeches',
        'Khaleeji',
        'Khaleeji Folk Shakshaka',
        'Khaleeji Folk Swahili',
        'Khaleeji Indie',
        'Khaleeji Musicals',
        'Khaleeji Operette',
        'Khaleeji Patriotic',
        'Khaleeji Plays',
        'Khaleeji Satire',
        'Khaleeji Shayla',
        'Khaleeji Soundtrack',
        'Khaleeji Tarab Classical',
        'Khaleeji Tarab Pop',
        'Khaleeji TV Shows',
        'Kurdi',
        'Lebanese Folk Ataba & Mijana',
        'Lebanese Folk Dabke',
        'Lebanese Folk Zajal',
        'Lebanese Indie',
        'Lebanese Musicals',
        'Lebanese Operette',
        'Lebanese Patriotic',
        'Lebanese Plays',
        'Lebanese Satire',
        'Lebanese Soundtrack',
        'Lebanese Tarab Classical',
        'Lebanese Tarab Pop',
        'Lebanese TV Shows',
        'Levantine Folk Bedouin',
        'Levantine Folk New Dabke Syrian',
        'Levantine Folk Qoudud & Muwashahat',
        'Levantine Indie',
        'Levantine Musicals',
        'Levantine Operette',
        'Levantine Patriotic',
        'Levantine Plays',
        'Levantine Satire',
        'Levantine Soundtrack',
        'Levantine Tarab Classical',
        'Levantine Tarab Pop',
        'Levantine TV Shows',
        'Maalaya Folk Dagni',
        'Moroccan Chaabi',
        'Moroccan Ra\u00ef',
        'Moroccan Sahraoui',
        'North African',
        'North African Folk Andalusi',
        'North African Folk Gnawa',
        'North African Folk Mezwed',
        'North African Folk Rif',
        'North African Indie',
        'North African Musicals',
        'North African Operette',
        'North African Patriotic',
        'North African Plays',
        'North African Ra\u00ef Classical',
        'North African Satire',
        'North African Soundtrack',
        'North African Tarab Classical',
        'North African Tarab Pop',
        'North African TV Shows',
        'Ra\u00ef',
        'Soufi',
        'Sudanese Folk Swahili',
        'Sudanese Indie',
        'Sudanese Musicals',
        'Sudanese Operette',
        'Sudanese Patriotic',
        'Sudanese Plays',
        'Sudanese Satire',
        'Sudanese Soundtrack',
        'Sudanese Tarab Classical',
        'Sudanese Tarab Pop',
        'Sudanese TV Shows',
        'Yemeni Folk Hadramawti',
        'Yemeni Folk Swahili',
        'Yemeni Indie',
        'Yemeni Musicals',
        'Yemeni Operette',
        'Yemeni Patriotic',
        'Yemeni Plays',
        'Yemeni Satire',
        'Yemeni Soundtrack',
        'Yemeni Tarab Classical',
        'Yemeni Tarab Pop',
        'Yemeni TV Shows'
    ],
    Asian: [
        'Chinese',
        'Chinese / Tibetan Native',
        'Chinese Folk',
        'Filipino / Bikol',
        'Filipino / Cebuano',
        'Filipino / Chavacano',
        'Filipino / Ilocano',
        'Filipino / Kapampangan',
        'Filipino / llonggo',
        'Filipino / Pangasinan',
        'Filipino / Tagalog',
        'Filipino / Waray',
        'Hong Kongese',
        'Indonesian',
        'Indonesian / Christian Pop',
        'Indonesian / Keroncong',
        'Indonesian / Koplo',
        'Indonesian / Religious',
        'Japanese',
        'Korean',
        'Malaysian',
        'Malaysian / Chinese New Year',
        'Malaysian / Christmas',
        'Malaysian / Deepavali',
        'Malaysian / Hari Raya',
        'Malaysian / Traditional',
        'OPM / 70\u2019s Pinoy Music',
        'OPM / 80\u2019s Pinoy Music',
        'OPM / 90\u2019s Pinoy Music',
        'OPM / Bisrock',
        'OPM / Cebuano',
        'OPM / Chavacano',
        'OPM / Folk Songs / Country Music',
        'OPM / Harana & Kundiman',
        'OPM / Hiligaynon',
        'OPM / Ilocano',
        'OPM / Kapampangan',
        'OPM / Pinoy Acoustic',
        'OPM / Pinoy Ballad',
        'OPM / Pinoy Love Songs',
        'Pinoy Rock',
        'Taiwanese',
        'Taiwanese Folk',
        'Tha\u00ef',
        'Tha\u00ef / Folk Music',
        'Tha\u00ef / Luk-Krung',
        'Tha\u00ef / Luk-Thung',
        'Tha\u00ef / Mo-Lam',
        'Tha\u00ef / Song For Life',
        'Vietnamese',
        'Vietnamese / B\u00e0i tr\u00f2i',
        'Vietnamese / Bolero',
        'Vietnamese / Ca Tr\u00f9',
        'Vietnamese / C\u1ea3i L\u01b0\u01a1ng',
        'Vietnamese / Ch\u00e8o',
        'Vietnamese / \u0110\u00ecnh Hu\u1ebf',
        'Vietnamese / \u0110\u1eddn Ca T\u00e0i T\u1eed',
        'Vietnamese / H\u00f2 Hu\u1ebf',
        'Vietnamese / L\u00fd',
        'Vietnamese / Nh\u1ea1c Cung',
        'Vietnamese / Quan H\u1ecd',
        'Vietnamese / Tr\u1ea7u V\u0103n',
        'Vietnamese / Tu\u1ed3ng',
        'Vietnamese / X\u1ea9m'
    ],
    Blues: ['Contemporary Blues', 'Traditional Blues'],
    Brazilian: [
        'Arrocha',
        'Axe',
        'Baiao',
        'Baile Funk',
        'Bossa Nova',
        'Brega',
        'Calypso',
        'Choro',
        'Forro',
        'Frevo',
        'Lambada',
        'MPB',
        'Pagode',
        'Samba',
        'Sertanejo'
    ],
    'Children Music': ['Holiday', 'Lullabies', 'Stories'],
    'Christian & Gospel': [
        'Arabic',
        'Arabic / Byzantine Chant',
        'Arabic / Christmas',
        'Arabic / Taranim & Tarateel',
        'Brazilian Gospel',
        'Christian',
        'Gospel',
        'Praise & Worship'
    ],
    Classical: [
        'Ballet',
        'Baroque',
        'Chamber',
        'Choral',
        'Concerto',
        'Contemporary Era',
        'Crossover',
        'Early Music',
        'Electronic',
        'High Classical',
        'Impressionist',
        'Medieval',
        'Minimalism',
        'Modern Compositions',
        'Opera',
        'Orchestral',
        'Religious',
        'Renaissance',
        'Romantic',
        'Solo Instrumental',
        'Solo Piano'
    ],
    Country: ['Contemporary', 'Pop', 'Traditional'],
    Dance: [
        'Acid House',
        'Afro House',
        'Amapiano',
        'Bass House',
        'Big Room',
        'Breaks',
        'Classic House',
        'Deep House',
        'Detroit Techno',
        'Disco Polo',
        'DJ Mix',
        'Down Beat / Trip Hop',
        'Drum & Bass',
        'Drum & Bass / Jungle',
        'Dub',
        'EDM / Commercial',
        'Electro',
        'Electro House',
        "Funky / Groove / Jackin' House",
        'Future House',
        'Garage / Bassline / Grime',
        'Hard Dance',
        'Hard Techno',
        'Hardcore',
        'House',
        'Indie Dance',
        'Latin House',
        'Mainstage',
        'Melodic House & Techno',
        'Minimal / Deep Tech',
        'New Trance',
        'Nu Disco / Disco',
        'Progressive House',
        'Psytrance',
        'Rave',
        'Soulful House',
        'Tech House',
        'Techno',
        'Trance',
        'Tropical House'
    ],
    'Easy Listening': ['Lounge', 'Swing', 'Vocal'],
    Electronic: [
        'Ambient',
        'Deep Dubstep / Grime',
        'Dubstep',
        'Electronica / Downtempo',
        'Experimental / Noise',
        'Lounge / Chillout',
        'Phonk',
        'Trap / Future Bass'
    ],
    Folk: ['Acoustic Folk', 'Contemporary Folk', 'Indie Folk', 'Pop Folk'],
    'Hip Hop/Rap': [
        'African Drill',
        'African Hip Hop',
        'Algerian Hip Hop',
        'Alternative',
        'Arabic Hip Hop',
        'Brazilian Hip Hop',
        'Canadian / Indigenous Hip Hop',
        'Chinese Hip Hop',
        'Cloud Rap / Sad Rap',
        'DJ Mix',
        'Dutch Hip Hop',
        'French Hip Hop',
        'French Trap',
        'German Hip Hop',
        'Grime',
        'Italian Hip Hop',
        'Jazz Hip Hop',
        'Khaleeji Hip Hop',
        'Latin Hip Hop',
        'Latin Trap',
        'LoFi',
        'Moroccan Hip Hop',
        'North African Hip Hop',
        'Old School Hip Hop',
        'Pop Urbaine',
        'Raggaeton',
        'Russian Hip Hop',
        'Swedish Hip Hop',
        'Taiwanese Hip Hop',
        'Tha\u00ef Hip Hop',
        'Trap',
        'Tunisian Hip Hop',
        'Turkish Hip Hop',
        'UK Hip Hop'
    ],
    Indian: [
        'Assamese',
        'Assamese Soundtrack',
        'Bengali',
        'Bengali Soundtrack',
        'Bhangra',
        'Bhojpuri',
        'Bhojpuri Soundtrack',
        'Bollywood',
        'Carnatic Classical',
        'Carnatic Classical Instrumental',
        'Children Song',
        'Classical',
        'Classical / Instrumental',
        'Classical / Vocal',
        'Devotional & Spiritual',
        'Dialogue',
        'DJ',
        'Folk',
        'Fusion',
        'Gazal',
        'Gujarati',
        'Gujarati Soundtrack',
        'Haryanvi',
        'Haryanvi Soundtrack',
        'Hindi',
        'Hindi Non Soundtrack',
        'Hindi Soundtrack',
        'Hindustani Classical',
        'Hindustani Classical Instrumental',
        'Indigenous',
        'Kannada',
        'Kannada Soundtrack',
        'Konkani',
        'Malayalam',
        'Malayalam Soundtrack',
        'Mappila',
        'Marathi',
        'Marathi Soundtrack',
        'Odia',
        'Odia Soundtrack',
        'Poetry',
        'Pop & Fusion',
        'Punjabi',
        'Punjabi Soundtrack',
        'Rabindra Sangeet',
        'Rajasthani',
        'Rajasthani Soundtrack',
        'Regional Indian',
        'Regional Indian Soundtrack',
        'Sanskrit',
        'Sanskrit Soundtrack',
        'Speech',
        'Sufi',
        'Tamil',
        'Tamil Soundtrack',
        'Telugu',
        'Telugu Soundtrack',
        'Traditional',
        'Urdu',
        'Urdu Soundtrack'
    ],
    Jazz: [
        'Bebop',
        'Big Band',
        'Classic',
        'Contemporary',
        'Dixie / Rag Time',
        'Free Jazz',
        'Fusion',
        'Jazz Funk',
        'Latin Jazz',
        'Nu Jazz / Acid Jazz',
        'Oriental Jazz',
        'Smooth Jazz',
        'Swing',
        'Traditional',
        'World'
    ],
    Latin: [
        'Argentine Cuarteto',
        'Argentine Cumbia',
        'Argentine Folklore',
        'Baladas',
        'Boleros',
        'Bossa Nova',
        'Caribbean',
        'Caribbean / Kompa',
        'Caribbean / Traditional\u00a0/ Biguine / Mazurka',
        'Caribbean / Zouk',
        'Cuban',
        'Ranchera',
        'Reggaeton',
        'Regional Mexicano',
        'Salsa / Merengue',
        'Son Jarocho',
        'Tango',
        'Tropical',
        'Urban'
    ],
    Metal: [
        'Black Metal',
        'Death Metal',
        'Deathcore',
        'Djent',
        'Doom',
        'Folk Metal',
        'Goth',
        'Grindcore',
        'Hard Rock',
        'Hardcore',
        'Heavy metal',
        'Industrial',
        'Metalcore',
        'Nu Metal',
        'Post Black',
        'Power Metal',
        'Prog Folk',
        'Proggressive Metal',
        'Sludge',
        'Symphonic Metal',
        'Thrash Metal'
    ],
    Pop: [
        'Alternative',
        'Arabic',
        'Batak',
        'Brazilian',
        'Canadian / Indigenous',
        'Cantopop',
        'Chinese Ethno Pop',
        'Colombian',
        'Contemporary / Adult',
        'Dance',
        'Dangdut',
        'Dutch',
        'Egyptian',
        'Electropop',
        'Folk',
        'French',
        'French / Vari\u00e9t\u00e9 Fran\u00e7aise',
        'French Zouk',
        'German',
        'Hokkien / Tawainese',
        'Hyperpop',
        'Indie',
        'Indonesian',
        'Iraqi',
        'Islamic Pop / Arabic',
        'Islamic Pop / Indonesian',
        'Italo',
        'J-Pop',
        'Jawa',
        'K-Pop',
        'Khaleeji',
        'Latin',
        'Lebanese',
        'Levantine',
        'Malaysian Indo',
        'Mandopop',
        'Manele',
        'Mexican',
        'Minang',
        'Mizrahi',
        'North African',
        'Pinoy',
        'R&B',
        'Rock',
        'Russian Chanson',
        'Schlager',
        'Sertanejo Universitario',
        'Singer Songwriter',
        'Spanish',
        'Sudanese',
        'Sunda',
        'Swedish',
        'Thai',
        'Turkish',
        'Yemeni'
    ],
    'R&B/Soul': ['Arabic', 'Contemporary', 'Disco', 'Funk & Soul', 'Hip Hop', 'Latin'],
    Reggae: ['Brazilian Reggae', 'Caribbean Dancehall', 'Caribbean Reggae', 'Dancehall', 'Dub', 'Roots'],
    Relaxation: ['Bali Spa', 'Meditation', 'World'],
    Rock: [
        'Alternative',
        'Brit-Pop Rock',
        'Classic',
        'Experimental',
        'Folk Rock',
        'Garage',
        'German Rock',
        'J-Rock',
        'Latin Rock',
        'Noise Rock',
        'Post Rock',
        'Progressive',
        'Psychedelic',
        "Rock 'n' Roll",
        'Rockabilly',
        'Russian Rock',
        'Shoegazing',
        'Singer / Songwriter'
    ],
    Various: [
        'Audiobook',
        'Audiobook / Children',
        'Audiobook / Comedy',
        'Audiobook / Documentation / Discovery',
        'Audiobook / Fiction',
        'Audiobook / Guide',
        'Audiobook / Historical',
        'Audiobook / Novel',
        'Audiobook / Theatre',
        'Comedy',
        'Fitness & Workout',
        'Holiday',
        'Holiday / Christmas',
        'Karaoke',
        'Radio Show',
        'Sound Effects',
        'Soundtrack',
        'Soundtrack / Children',
        'Soundtrack / Movie',
        'Soundtrack / Musical',
        'Soundtrack / TV',
        'Speeches / Spoken Word'
    ],
    'World Music / Regional Folklore': [
        'Australian',
        'Cajun / Creole',
        'Eastern European',
        'Ethnic',
        'Farsi',
        'Flamenco',
        'French',
        'German / Volksmusik',
        'Greek',
        'Indian Ocean / Maloya',
        'Indian Ocean / S\u00e9ga',
        'Irish / Celtic',
        'Italian',
        'Jewish Music',
        'Klezmer',
        'Mediterranean',
        'Mizrahi',
        'Nordic',
        'North American',
        'Pacific Island',
        'Polish',
        'Russian Folk',
        'Russian Poetry',
        'Spanish',
        'Sufi & Ghazals',
        'Turkish / Fantezi',
        'Turkish / Halk',
        'Turkish / Sanat',
        'Turkish / \u00d6zg\u00fcn',
        'Western Europe',
        'Worldbeat'
    ]
};

export const releasePriceTierOptions = {
    SINGLE: [
        {
            label: 'Digital45 - (1,49€ / 1,99$)',
            value: 'DIGITAL45'
        }
    ],
    EP: [
        {
            label: 'MiniEP - (1,99€ / 2,99$)',
            value: 'MINIEP'
        },
        {
            label: 'EP - (2,99€ / 3,99$)',
            value: 'EP'
        },
        {
            label: 'MaxiEP - (3,99€ / 4,99$)',
            value: 'MAXIEP'
        },
        {
            label: 'MaxiEP Two - (4,99€ / 4,99$)',
            value: 'MAXIEP TWO'
        },
        {
            label: 'BudgetOne - (4,99€ / 5,99$)',
            value: 'BUDGETONE'
        },
        {
            label: 'Budget - (5,99€ / 6,99$)',
            value: 'BUDGET'
        },
        {
            label: 'Back - (6,99€ / 7,99$)',
            value: 'BACK'
        }
    ],
    ALBUM: [
        {
            label: 'Back Catalog - (7,99€ / 8,99$)',
            value: 'BACK CATALOG'
        },
        {
            label: 'Midline - (8,99€ / 9,99$)',
            value: 'MIDLINE'
        },
        {
            label: 'Midfront - (9,99€ / 10,99$)',
            value: 'MIDFRONT'
        },
        {
            label: 'Midfront Two - (10,99€ / 10,99$)',
            value: 'MIDFRONT TWO'
        },
        {
            label: 'Premium - (11,99€ / 11,99$)',
            value: 'PREMIUM'
        },
        {
            label: 'Deluxe One - (12,99€ / 12,99$)',
            value: 'DELUXE ONE'
        },
        {
            label: 'Deluxe Two - (13,99€ / 13,99$)',
            value: 'DELUXE TWO'
        },
        {
            label: 'Deluxe Three - (14,99€ / 14,99$)',
            value: 'DELUXE THREE'
        },
        {
            label: 'Deluxe Four - (15,99€ / 15,99$)',
            value: 'DELUXE FOUR'
        }
    ]
};

type defaultSelections = {
    main_artists: string[];
    featuring_artists?: string[];
};

export const defaultSelectionsAtom = atom<defaultSelections>({
    main_artists: []
});

// atom with the action ref of the releases table
export const releasesActionRefAtom = atom((read) => useRef<ActionType>());

export const artistsActionRefAtom = atom((read) => useRef<ActionType>());

export const labelsActionRefAtom = atom((read) => useRef<ActionType>());

export const assetLabelsActionRefAtom = atom((read) => useRef<ActionType>());

export const releasesViewAtom = atomWithHash<string | undefined>('releasesView', 'verified');
export const draftsViewAtom = atomWithHash<string | undefined>('draftsView', 'pending');

export const assetLabelsAtom = atomWithQuery<API.MusicDistribution.AssetLabel[]>((get) => ({
    queryKey: ['asset_labels'],
    queryFn: async () => {
        const response = await axiosApiInstance.get(`/api/products/asset_labels/`);
        // console.log(response.data);
        return response.data;
    }
}));

export const newReleaseUploadQueueAtom = atom<{
    cover_art: Function;
    art_tracks: Function[];
}>({ cover_art: () => {}, art_tracks: [] });
